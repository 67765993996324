<template>
  <modal
    name="offer-modal-archive-edit"
    :resizable="true"
    :height="'auto'"
    :width="'80%'"
    :scrollable="true"
  >
    <div class="row bg-secondary">
      <!-- <h1> Installation modal </h1> -->
      <div class="col-6">
        <h3 style="color: #fff">Edycja oferty {{ offer.offerNumber }}</h3>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="float-right">
          <button
            class="btn btn-sm btn-pill btn-danger my-1 mx-2"
            @click="deleteOffer(offer)"
          >
            Usuń ofertę
          </button>

          <button
            class="btn btn-sm btn-pill btn-warning my-1 mx-2"
            @click="updateOffer(offer)"
          >
            Zapisz
          </button>

          <button
            class="btn btn-sm btn-pill btn-danger my-1 mx-2"
            @click="$emit('closeModalEdit')"
          >
            Zamknij
          </button>
        </div>
      </div>
    </div>
    <div class="col-10 mx-auto justify-content-center">
      <input
        class="form-control mt-3 mb-1"
        type="text"
        placeholder="Kontrahent"
        v-model="offer.contractor"
      />
      <input
        class="form-control mt-3 mb-1"
        type="text"
        placeholder="Nagłówek promocji"
        v-model="offer.header"
      />
      <textarea
        class="form-control mt-3 mb-1"
        type="text"
        placeholder="Nagłówek oferty"
        v-model="offer.header2"
      />

      <textarea
        class="form-control mt-3 mb-1"
        type="text"
        placeholder="Szczegóły oferty"
        v-model="offer.subheader"
      />
      <form class="add-form bg-light border" @submit.prevent>
        <table class="table table-bordered">
          <thead>
            <th>przedmiot</th>
            <th>jednostka miary</th>
            <th>ilość</th>
            <th>cena netto</th>
            <th>wartość netto</th>
            <th>wartość brutto</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in offer.offer" :key="item.id">
              <td>
                <input
                  type="text"
                  class="netto form-control mt-1 mb-1"
                  v-model="item.name"
                  :key="item.id"
                />
              </td>
              <td>
                <input
                  class="form-control mt-1 mb-1"
                  type="text"
                  v-model="item.measure"
                  :key="item.id"
                />
              </td>
              <td>
                <input
                  type="number"
                  class="netto form-control mt-1 mb-1"
                  v-model="item.amount"
                  :key="item.id"
                  step="0.01"
                />
              </td>
              <td>
                <input
                  type="number"
                  class="netto form-control mt-1 mb-1"
                  :id="index"
                  v-model="item.price"
                  :key="item.id"
                  step="0.01"
                />
              </td>
              <td>
                <span v-if="item.price">{{ (item.price * item.amount).toFixed(2) }}</span>
              </td>
              <td>
                <span v-if="item.price">{{
                  (item.price * item.amount * offer.vat).toFixed(2)
                }}</span>
              </td>
              <td>
                <button class="btn btn-small btn-danger" @click="deleteItem(index)">
                  usuń
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>Suma</td>
              <td>
                <select
                  class="form-control mt-1 mb-1"
                  name="vat"
                  id="vat-value"
                  v-model="offer.vat"
                >
                  <option value="0">VAT</option>
                  <option value="1.08">8%</option>
                  <option value="1.23">23%</option>
                </select>
              </td>
              <td></td>
              <td></td>
              <td>{{ totalPriceNetto }}</td>
              <td>{{ totalPriceBrutto }}</td>
            </tr>
          </tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <button
                style="width: 100%; height: 65px"
                class="btn btn-success btn-sm mt-1"
                @click="addElement()"
              >
                Dodaj element
              </button>
            </td>
          </tr>
          <!-- <tfoot>
            <tr>
              <td>Suma</td>
              <td>
                <select
                  class="form-control mt-1 mb-1"
                  name="vat"
                  id="vat-value"
                  v-model="offer.vat"
                  @change="total()"
                >
                  <option value="0">VAT</option>
                  <option value="1.08">8%</option>
                  <option value="1.23">23%</option>
                </select>
              </td>
              <td></td>
              <td></td>
              <td>{{ totalPriceNetto.toFixed(2) }}</td>
              <td>{{ totalPriceBrutto.toFixed(2) }}</td>
            </tr>
          </tfoot> -->
        </table>

        <label style="cursor: pointer" for="customInputFile">
          <svg
            width="1.5em"
            height="1.5em"
            viewBox="0 0 16 16"
            class="bi bi-camera"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M15 12V6a1 1 0 0 0-1-1h-1.172a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 9.173 3H6.828a1 1 0 0 0-.707.293l-.828.828A3 3 0 0 1 3.172 5H2a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"
            />
            <path
              fill-rule="evenodd"
              d="M8 11a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
            />
            <path d="M3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
          </svg>
        </label>

        <input
          style="display: none"
          id="customInputFile"
          class="custom-file-input"
          type="file"
          accept="image/*"
          @change="uploadImage"
        />
        <hr />
        <img :src="`${offer.imageUrl}`" alt="" />

        <textarea
          class="form-control mt-3 mb-1"
          type="text"
          placeholder="Uwagi do oferty"
          v-model="offer.notice"
        />
      </form>
    </div>
  </modal>
</template>
<script>
export default {
  props: ["offer"],
  data() {
    return {
      imageURL: "",
      date: new Date(),
    };
  },
  computed: {
    totalPriceNetto() {
      let priceNetto = 0;
      this.offer.offer.forEach((item) => {
        if (item.price) {
          priceNetto += item.price * item.amount;
        }
      });
      return priceNetto.toFixed(2);
    },
    totalPriceBrutto() {
      let priceBrutto = 0;
      this.offer.offer.forEach((item) => {
        if (item.price) {
          priceBrutto += item.price * item.amount * this.offer.vat;
        }
      });
      return priceBrutto.toFixed(2);
    },
  },
  methods: {
    updateOffer() {
      this.$store
        .dispatch("updateOffer", {
          id: this.offer.id,
          offer: this.offer.offer,
          vat: this.offer.vat,
          netto: parseFloat(this.totalPriceNetto),
          brutto: parseFloat(this.totalPriceBrutto),
          imageUrl: this.offer.imageUrl,
          header: this.offer.header,
          notice: this.offer.notice,
          header2: this.offer.header2,
          subheader: this.offer.subheader,
          contractor: this.offer.contractor,
        })
        .then(() => {
          setTimeout(() => {
            this.$emit("closeModalEdit");
          }, 500);
        });
    },
    // total() {
    //   this.totalPriceNetto = 0;
    //   this.totalPriceBrutto = 0;
    //   this.offer.offer.forEach((item) => {
    //     console.log(item.price + " ->edit");
    //     if (item.price) {
    //       this.totalPriceNetto += item.price * item.amount;
    //       this.totalPriceBrutto += item.price * item.amount * this.offer.vat;
    //     }
    //   });
    // },
    addElement() {
      this.offer.offer.push({ name: "wpisz nazwę" });
    },
    deleteOffer() {
      if (confirm("Czy jesteś pewien ze chcesz usunąć ofertę?")) {
        this.$store.dispatch("deleteOffer", {
          id: this.offer.id,
        });
        this.$emit("closeModalEdit");
      }
    },
    deleteItem(index) {
      console.log(index);
      this.offer.offer.splice(index, 1);
    },

    uploadImage() {
      var img = document.getElementById("customInputFile").files[0];
      this.getBase(img, (response) => {
        this.offer.imageUrl = response;
      });
    },

    getBase(img, callback) {
      var reader = new FileReader();
      reader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result;
        callback(srcData);
      };
      reader.readAsDataURL(img);
      //   console.log("outer", this.imageURL);
    },
  },
};
</script>
