<template>
  <modal
    name="offer-modal-archive"
    :resizable="true"
    :height="'auto'"
    :width="'80%'"
    :scrollable="true"
  >
    <div class="row bg-secondary">
      <!-- <h1> Installation modal </h1> -->
      <div class="col-6">
        <p v-html="offer.header" class="float-left align-middle text-white mt-2">
          {{ offer.header }}
        </p>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="float-right">
          <button
            class="btn btn-sm btn-pill btn-warning my-1 mx-2"
            @click="openOfferModalEdit(offer)"
          >
            Edytuj
          </button>
          <button
            id="editinstallationButton"
            class="btn btn-sm btn-pill btn-info my-1 mx-2"
            @click="generateOffer(offer)"
          >
            Generuj PDF
          </button>
          <button
            class="btn btn-sm btn-pill btn-danger my-1 mx-2"
            @click="$emit('closeModal')"
          >
            Zamknij
          </button>
        </div>
      </div>
    </div>
    <div id="toPrint" class="mx-3">
      <div class="row text-right">
        <div class="col-12">
          <img src="../assets/logo.png" alt="" />
          <p>
            ul. Wąska 4 Zaborze, 43-520 Chybie <br />
            biuro@klim-bud.pl <br />
            tel: 574 559 890
            <br />
            NIP:548-235-02-89<br />
            REGON: 241002771 <br />
            www.klim-bud.pl
          </p>
        </div>
      </div>
      <div class="row text-right">
        <div class="col-12 my-4 mb-3">Chybie dn. {{ date.toLocaleDateString("pl") }}</div>
      </div>
      <div class="row my-4 text-center">
        <div class="col-12">
          <h3 class="my-4">OFERTA CENOWA</h3>
          <h5>{{ offer.offerNumber }}</h5>
          <br /><br />
          <h5 class="my-4" v-html="offer.header2">
            {{ offer.header2 }}
          </h5>
          <br />
          <p class="my-4" v-html="offer.subheader">
            {{ offer.subheader }}
          </p>
        </div>
      </div>

      <div class="html2pdf__page-break"></div>

      <div class="row">
        <div class="col-12 text-right">
           <img src="../assets/logo.png" alt="" />
        </div>
       
      </div>

      <!-- <div class="row text-right">
        <div class="col-12">
          <p>
            ul. Wąska 4 Zaborze, 43-520 Chybie <br />
            biuro@klim-bud.pl <br />
            tel: 504 041 946
            <br />
            NIP:548-235-02-89<br />
            REGON: 241002771 <br />
            www.klim-bud.pl
          </p>
        </div>
      </div> -->

      <div class="row text-center">
        <div class="col-12 my-5">
          <h5 v-html="offer.header">{{ offer.header }}</h5>
        </div>

        <!-- <div class="row">
          <div class="col-12 text-center">
            <img style="max-height: 500px" :src="`${imageUrl}`" alt="" />
          </div>

          <div class="col-3 ml-5 text-center border">
            <small>Uwagi do oferty:</small> <br />
            {{ notice }}
          </div>
        </div> -->

        <table style="font-size: 14px" class="table mt-2 table-sm table-striped">
          <thead>
            <th>Przedmiot</th>
            <th>j.m.</th>
            <th>ilość</th>
            <th>cena netto</th>
            <th>wartość netto</th>
            <th>wartość brutto</th>
          </thead>
          <tbody>
            <tr v-for="item in offer.offer" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.measure }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.price }}</td>
              <td>{{ (item.amount * item.price).toFixed(2) }}</td>
              <td>{{ (item.amount * item.price * offer.vat).toFixed(2) }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><b>Suma PLN:</b></td>
              <td></td>
              <td></td>
              <td v-if="offer.vat === '1.08'"><b>VAT: 8%</b></td>
              <td v-else><b>VAT: 23%</b></td>
              <td>
                <b>{{ totalPriceNetto }}</b>
              </td>
              <td>
                <b>{{ totalPriceBrutto }}</b>
              </td>
            </tr>
          </tfoot>
        </table>

        <div class="col-12 my-3 text-center">
          <img
            class="img-fluid"
            style="max-height: 350px"
            :src="`${offer.imageUrl}`"
            alt=""
          />
        </div>

        <div class="col-6 my-5 text-center">
          <small>Uwagi do oferty:</small> <br />
          {{ offer.notice }}
        </div>
        <div class="col-6 my-5 text-center">
          <small>Wykonawca F.H.U KLIM-BUD:</small> <br />
          {{ offer.user.name }} <br />
          {{ offer.user.phoneNumber }}
        </div>
      </div>
    </div>
    <offer-modal-archive-edit
      @closeModalEdit="closeModalEdit()"
      :offer="offer"
    ></offer-modal-archive-edit>
  </modal>
</template>
<script>
import html2pdf from "html2pdf.js";
import OfferModalArchiveEdit from "../components/OfferModalArchiveEdit";
export default {
  props: ["offer"],
  data() {
    return {
      // totalPriceNetto: 0,
      // totalPriceBrutto: 0,
      date: new Date(),
    };
  },
  components: {
    "offer-modal-archive-edit": OfferModalArchiveEdit,
  },
  computed: {
    totalPriceNetto() {
      let priceNetto = 0;
      this.offer.offer.forEach((item) => {
        if (item.price) {
          priceNetto += item.price * item.amount;
        }
      });
      return priceNetto.toFixed(2);
    },
    totalPriceBrutto() {
      let priceBrutto = 0;
      this.offer.offer.forEach((item) => {
        if (item.price) {
          priceBrutto += item.price * item.amount * this.offer.vat;
        }
      });
      return priceBrutto.toFixed(2);
    },
  },
  methods: {
    generateOffer() {
      console.log(this.imageUrl);
      console.log(this.offersElements);
      var element = document.getElementById("toPrint");
      html2pdf(element, {
        margin: 0.1,
        filename: prompt("Podaj nazwę pliku"),
        image: { type: "jpeg", quality: 1 },
        html2canvas: { dpi: 192 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      });
    },
    openOfferModalEdit(offer) {
      console.log(offer);
      window.scrollTo(0, 0);
      this.$modal.show("offer-modal-archive-edit");
      this.offer = offer;
      // Prevent navigating to narrower view (default vue-cal behavior).
    },
    closeModalEdit() {
      // this.total();
      setTimeout(() => {
        // this.events = [];
        // console.log(this.installations);
        //   //   const item1 = this.config.chart.items["1"];
        //   //   item1.label = "label changed dynamically";
        //   //   item1.time.end += 2 * 24 * 60 * 60 * 1000;
        // this.setEvents();
      }, 100);
      // this.total();
      this.$modal.hide("offer-modal-archive-edit");
    },
    // total() {
    //   this.totalPriceNetto = 0;
    //   this.totalPriceBrutto = 0;
    //   this.offer.offer.forEach((item) => {
    //     console.log(item + " ->closeModalEdit");
    //     if (item.price) {
    //       this.totalPriceNetto += item.price * item.amount;
    //       this.totalPriceBrutto += item.price * item.amount * this.offer.vat;
    //     }
    //   });
    // },
  },
};
</script>
