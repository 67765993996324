<template>
  <div>
    <!-- HEADER STRONY -->
    <div class="row bg-light mt-1 ml-0">
      <div class="col-8">
        <router-link to="/offers">
          <button style="width: 10%" class="btn btn-info mt-1 mb-1 float-left">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-folder-plus"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.828 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91H9v1H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181L15.546 8H14.54l.265-2.91A1 1 0 0 0 13.81 4H9.828zm-2.95-1.707L7.587 3H2.19c-.24 0-.47.042-.684.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293z"
              />
              <path
                fill-rule="evenodd"
                d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
              />
            </svg>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
        <button @click="getData()" class="btn btn-warning ml-1 mt-1 mb-1 float-left">
          Pobierz Oferty
        </button>
        Ilość:
        <input type="number" v-model="lastOffers" value="100">
        Data od:
        <input type="date" v-model="lastOffersStartDate">
        Data do:
        <input type="date" v-model="lastOffersEndDate"> 
      </div>
      <div class="col-4">
        <input
          v-model="search"
          class="form-control w-50 float-right form-control-sm mt-1 mr-3"
          type="search"
          placeholder="Wyszukaj oferte"
          aria-label="Search"
        />
      </div>
    </div>

    <!-- TABLEA -->
   
    <table id="offers-archive" class="table">
      <thead>
        <tr>
          <th scope="col">Dla</th>
          <th scope="col">Numer Oferty</th>
          <th scope="col">Data</th>
          <th scope="col">Urządzenie</th>
          <th scope="col">Kwota netto</th>

          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" v-for="offer in filteredOffers" :key="offer.id">
          <th scope="row">
            {{ offer.contractor }}
            <!-- <div v-if="installation.contractor.type == 'company'">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-building"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                />
                <path
                  d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
                />
              </svg>
            </div>
            <div v-else>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-person"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                />
              </svg>
            </div> -->
          </th>
          <td>{{ offer.offerNumber }}</td>
          <td>{{ new Date(offer.date.seconds * 1000).toLocaleDateString() }} </td>

          <td v-if="offer.offer">{{ offer.offer[0].name }}</td>
          <td>{{ offer.netto.toFixed(2) }} pln</td>
          <td></td>

          <td>
            <button
              class="btn btn-sm align-middle btn-info"
              @click="openOfferModal(offer)"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-file-earmark-arrow-up"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
                />
                <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                <path
                  fill-rule="evenodd"
                  d="M8 12a.5.5 0 0 0 .5-.5V7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 7.707V11.5a.5.5 0 0 0 .5.5z"
                />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <offer-modal-archive @closeModal="closeModal()" :offer="offer"></offer-modal-archive>
  </div>
</template>
<script>
import { mapState } from "vuex";
import OfferModalArchive from "../components/OfferModalArchive";

export default {
  data() {
    return {
      //   installation: {
      //     brand: "",
      //     model: "",
      //     plate: "",
      //     vin: "",
      //     year: "",
      //     imageUrl: "",
      //   },
      //   openForm: false,
      search: "",
      installation: "",
      lastOffers:100,
      lastOffersEndDate:"",
      lastOffersStartDate:"",
      offer: [],
    };
  },
  components: {
    "offer-modal-archive": OfferModalArchive,
  },

  computed: {
    ...mapState(["userProfile", "offers"]),
    sortedOffers() {
      return this.offers
        .slice()
        .sort((a, b) => new Date(b.createOn.seconds) - new Date(a.createOn.seconds));
    },
    filteredOffers() {
      return this.sortedOffers.filter((item) => {
        if (item.contractor.includes(this.search)) {
          return item;
        } else if (item.offerNumber.toUpperCase().includes(this.search.toUpperCase())) {
          return item;
        }
      });
    },
  },
  methods: {
    //    openInstallationModal(installation) {
    //   this.$modal.show("installation-modal");
    //   this.installation = installation;
    // },
    openOfferModal(offer) {
      console.log(offer);
      window.scrollTo(0, 0);
      this.$modal.show("offer-modal-archive");
      this.offer = offer;
      // Prevent navigating to narrower view (default vue-cal behavior).
    },
    getData() {
      this.$store.dispatch("getOffers",{
        lastOffers: this.lastOffers,
        startDate: this.lastOffersStartDate,
        endDate: this.lastOffersEndDate});
    },
    closeModal() {
      setTimeout(() => {
        // this.events = [];
        // console.log(this.installations);
        //   //   const item1 = this.config.chart.items["1"];
        //   //   item1.label = "label changed dynamically";
        //   //   item1.time.end += 2 * 24 * 60 * 60 * 1000;
        // this.setEvents();
      }, 100);
      this.$modal.hide("offer-modal-archive");
    },
  },
};
</script>
<style>
.customer {
  background-color: rgba(0, 123, 255, 0.1);
}
.company {
  background-color: rgba(23, 162, 184, 0.1);
}
</style>
